<template>
  <div class="relative w-full backdrop-blur-xl rounded-3xl shadow-2xl overflow-hidden 
    bg-gradient-to-b from-white/80 to-white/60 border border-white/20">
    <div class="px-4 py-3 bg-gradient-to-b from-gray-100/80 to-white/80 border-b border-gray-100/50 
      flex items-center gap-2">
      <div class="flex gap-2">
        <div class="w-3 h-3 rounded-full bg-[#FF5F57] shadow-lg" />
        <div class="w-3 h-3 rounded-full bg-[#FFBD2E] shadow-lg" />
        <div class="w-3 h-3 rounded-full bg-[#28C840] shadow-lg" />
      </div>
    </div>
    <div class="p-8 md:p-12">
      <slot></slot>
    </div>
  </div>
</template>