<template>
  <div class="min-h-screen bg-[#F5F5F7]">
    <!-- Background Effects -->
    <div class="fixed inset-0 -z-10">
      <div
        class="absolute -top-40 -left-40 w-96 h-96 bg-blue-100 rounded-full mix-blend-multiply filter blur-3xl opacity-50 animate-blob"
      />
      <div
        class="absolute -bottom-40 -right-40 w-96 h-96 bg-purple-100 rounded-full mix-blend-multiply filter blur-3xl opacity-50 animate-blob animation-delay-2000"
      />
      <div
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-pink-100 rounded-full mix-blend-multiply filter blur-3xl opacity-50 animate-blob animation-delay-4000"
      />
    </div>

    <div class="max-w-7xl mx-auto px-8 py-12">
      <!-- Header -->
      <header class="flex justify-between items-center mb-32">
        <div
          class="text-2xl md:text-3xl font-semibold tracking-tight text-gray-900"
        >
          Blue Pencils
        </div>
        <div class="flex items-center gap-1">
          <Sparkles class="w-4 h-4 text-blue-600" />
          <span class="text-sm text-gray-500">Crafting the future</span>
        </div>
      </header>

      <!-- Main Content -->
      <div class="grid grid-cols-1 gap-24">
        <!-- Hero Section -->
        <div>
          <h1
            class="text-7xl md:text-8xl lg:text-9xl font-bold text-gray-900 tracking-tight leading-[1.1]"
          >
            <span class="inline-block">{{ phrases[currentPhraseIndex] }}</span>
            <div
              class="mt-4 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent"
            >
              Digital Products
              <br />
              of Tomorrow
            </div>
          </h1>
        </div>

        <!-- Vision Window -->
        <MacWindow>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div>
              <h2
                class="text-3xl font-semibold text-gray-900 mb-8 tracking-tight"
              >
                Building experiences that define tomorrow's technology
              </h2>
              <p class="text-lg text-gray-600 leading-relaxed mb-8">
                Our vision transcends conventional product development. We're a
                team of innovators, dreamers, and builders, crafting digital
                solutions that push the boundaries of what's possible.
              </p>
              <p class="text-lg text-gray-600 leading-relaxed mb-8">
                Each product we develop is a step towards reimagining how
                technology integrates into everyday life.
              </p>
              <a
                href="mailto:hello@bluepencils.co"
                class="group inline-flex items-center gap-3 px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-xl text-lg font-medium hover:opacity-90 transition-all duration-300 shadow-lg hover:shadow-xl"
              >
                <span>Join our journey</span>
                <ArrowRight
                  class="w-5 h-5 group-hover:translate-x-1 transition-transform"
                />
              </a>
            </div>
            <div class="relative flex items-center justify-center">
              <img
                src="./creative-work.svg"
                alt="Creative Work Illustration"
                class="w-full h-auto object-contain max-w-md transform transition-all duration-500 hover:scale-100"
              />
            </div>
          </div>
        </MacWindow>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { Sparkles, ArrowRight } from 'lucide-vue-next'
import MacWindow from './components/MacWindow.vue'

const currentPhraseIndex = ref(0)
const phrases = ['We innovate.', 'We create.', 'We build.', 'We transform.']

let intervalId

onMounted(() => {
  intervalId = setInterval(() => {
    currentPhraseIndex.value = (currentPhraseIndex.value + 1) % phrases.length
  }, 3000)
})

onUnmounted(() => {
  if (intervalId) clearInterval(intervalId)
})
</script>

<style scoped>
.ease-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
</style>
